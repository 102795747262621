import _export from "../internals/export";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _indexedObject from "../internals/indexed-object";
import _toIndexedObject from "../internals/to-indexed-object";
import _arrayMethodIsStrict from "../internals/array-method-is-strict";
var $ = _export;
var uncurryThis = _functionUncurryThis;
var IndexedObject = _indexedObject;
var toIndexedObject = _toIndexedObject;
var arrayMethodIsStrict = _arrayMethodIsStrict;
var un$Join = uncurryThis([].join);
var ES3_STRINGS = IndexedObject != Object;
var STRICT_METHOD = arrayMethodIsStrict("join", ","); // `Array.prototype.join` method
// https://tc39.es/ecma262/#sec-array.prototype.join

$({
  target: "Array",
  proto: true,
  forced: ES3_STRINGS || !STRICT_METHOD
}, {
  join: function join(separator) {
    return un$Join(toIndexedObject(this), separator === undefined ? "," : separator);
  }
});
export default {};